
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/carousel.scss';
  
.container {
  position: relative;
  max-width: 1440px;
  min-height: 644px;
  margin: 0 auto;
  text-align: -webkit-center;

  @include breakpoint(medium) {
    min-height: 524px;
  }

  @include breakpoint(large) {
    min-height: 462px;
  }
}

.mozilla {
  text-align: -moz-center;
}

.headers {
  align-self: center;
}

.heading {
  margin-top: 100px;
  font-family: $font-axiforma;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
  @include breakpoint(medium) {
    margin-top: 40px;
  }
}

.subheading {
  color: white;
  line-height: 1.37;
}

.content {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  padding: 48px 16px;

  @include breakpoint(medium) {
    padding: 64px 32px;
  }

  @include breakpoint(large) {
    padding: 64px;
  }

  .addressContainer {
    margin: 16px 0 0 0;
  }

  .addressLabel {
    color: white;
    font-family: $font-primary;
    font-size: 10px;
    font-weight: 700;
    line-height: 1.4;
  }

  .logoWrapper {
    width: 207px;
    height: 90px;
  }
}

.backgroundImage {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &.backgroundPositionCenter {
    object-position: center;
  }

  &.backgroundPositionRight {
    object-position: right;
  }
}


