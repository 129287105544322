
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/carousel.scss';
  
.headline {
  margin: 0 0 40px;
  color: $color-medium-gray;
  text-align: center;

  @include breakpoint(medium) {
    padding-top: 66px;
    border-top: 1px solid $color-medium-gray;
  }
}

.jupiterLogo {
  height: 18px;
  margin: 0;
  padding-bottom: 22px;
  text-align: end;
}

.accordion {
  margin: 0;
  padding: 0;
  list-style: none;
}

.accordionItem {
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 0 30px rgb(0, 0, 0, 0.1);
  background-color: $color-white;
  .button {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    border-radius: 8px;
    color: #333333;
    font-family: $font-axiforma;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;
  }

  .icon {
    margin-left: 8px;
    &.active {
      transform: rotate(180deg);
    }
  }
}

.subheadWrapper {
  height: auto;
  overflow: hidden;
  transition: height ease 0.2s;
}

.subhead {
  margin: 0;
  // padding: 0 30px 20px;
  color: $color-matterhorn;
  padding: 20px 0;
  text-align: left;
  a {
    font-weight: bold;
    text-decoration: underline;
    color: $color-dark-blue
  }
}

.subheadSpacing {
  padding: 0;
}

//FAQ
.faqAccordionItem {
  margin-bottom: 10px;
  border-bottom: 1px solid #787878;
  .button {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px 0 30px;
    background-color: $color-white;
    color: $color-dark-charcoal;
    font-family: $font-axiforma;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    cursor: pointer;
  }

  .icon {
    margin-left: 24px;
    &.active {
      transform: rotate(180deg);
    }
  }
}

.faqSubhead {
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 1.43;
  color: $color-matterhorn;
  text-align: left;
  a {
    font-weight: bold;
    text-decoration: underline;
    color: $color-dark-blue
  }
}