
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/carousel.scss';
  
.column {
  display: flex;
  flex-direction: column;
  max-width: 704px;
  margin: 0 auto;
  background-color: $color-white;

  @include breakpoint(large) {
    max-width: 1440px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 48px 16px;

  @include breakpoint(large) {
    flex-direction: row;
    height: 400px;
    padding: 64px;
  }

  .textContent {
    display: flex;
    flex-direction: column;
    padding-bottom: 48px;

    @include breakpoint(large) {
      flex: 1;
      align-items: flex-start;
      justify-content: center;
      padding: 64px 64px 64px 0;
    }
  }

  .headline {
    margin: 0;
    font-family: $font-axiforma;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }

  .subheadline {
    margin: 16px 0 24px;
    font-family: $font-primary;
  }

  a {
    text-decoration: none;
  }

  .cta {
    width: fit-content;
    height: auto;
    padding: 16px 32px;
    background: #de3700;
    font-family: $font-axiforma;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 100%;

    &:hover,
    &:focus {
      border: none;
      background: #cc461a;
      color: $color-white;
    }

    &:focus {
      outline: 2px dashed #787878;
    }
  }

  .imgWrapper {
    position: relative;
    width: 100%;
    max-width: 704px;
    height: auto;
    aspect-ratio: 1.56;

    @include breakpoint(large) {
      flex: 1;
      aspect-ratio: auto;
      margin: auto 0;
    }
  }

  .image {
    object-fit: contain;
    width: 100%;

    @include breakpoint(large) {
      object-position: right;
    }
  }
}
