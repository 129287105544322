
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/carousel.scss';
  
.container {
  display: flex;
  justify-content: center;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  background-color: $color-white;
  background-position: center 35%;
  background-size: cover;
  @include breakpoint(medium) {
    background-size: 125%;
  }

  .gridContainer {
    margin-right: auto;
    margin-left: auto;
    @media only screen and (max-width: 1023px) and (min-width: 639px) {
      margin-right: 32px;
      margin-left: 32px;
    }
    @include breakpoint(small) {
      margin-right: 16px;
      margin-left: 16px;
    }
  }

  .btn {
    width: 300px;
    height: 48px;
    margin-bottom: 48px;
    padding: 16px;
    border: 2px solid #fff;
    border-radius: 30px;
    background: transparent;
    color: #fff;
    font-family: $font-axiforma;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    @media screen and (max-width: 640px) {
      width: 100%;
    }
    @include breakpoint(medium) {
      margin-bottom: 0;
    }

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(83, 86, 90, 0.1) 0%,
          rgba(83, 86, 90, 0.1) 100%
        ),
        #fff;
      color: #111;

      svg > path {
        fill: #111;
      }
    }

    &:focus {
      outline: 2px dashed #787878;
    }
  }

  .epqBtn {
    margin-top: 0;
    margin-bottom: 10px;
    border: none;
    background: #de3700;
    color: $color-white;
    font-size: 20px;

    &:hover,
    &:focus {
      border: none;
      background: #cc461a;
      color: $color-white;
    }

    &:focus {
      outline: 2px dashed #787878;
    }
  }

  .content {
    @include breakpoint(large) {
      display: flex;
      max-width: 916px;
      max-height: 268px;
    }
    @include breakpoint(medium) {
      display: flex;
      max-height: 290px;
    }
    @include breakpoint(small) {
      display: block;
      max-height: 488px;
    }
    a {
      text-decoration: none;
    }
    .phoneButton {
      @include breakpoint(large) {
        margin: auto;
        margin-left: 24px;
      }
      @include breakpoint(medium) {
        margin-left: 24px;
      }

      @include breakpoint(small) {
        justify-content: center;
        margin: auto;
      }

      svg {
        padding-right: 8px;
      }
    }

    .heroContentWrapper {
      @media only screen and (max-width: 1023px) and (min-width: 639px) {
        max-width: 412px;
        margin-right: 12px;
        margin-bottom: 15px;
      }
      @include breakpoint(large) {
        max-height: 268px;
        margin: auto;
        margin-right: 24px;
        color: $color-dark-gray;
        text-align: left;
      }

      .headline {
        margin-top: 48px;
        margin-bottom: 16px;
        font-family: $font-axiforma;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25;

        @include breakpoint(large) {
          margin-top: 64px;
          font-size: 32px;
        }
      }

      .subHeadline {
        max-width: 624px;
        max-height: 44px;
        margin-top: 0;
        font-family: 'Open Sans';
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4;

        @include breakpoint(medium) {
          margin-bottom: 64px;
        }

        @include breakpoint(small) {
          max-height: 88px;
          margin-bottom: 48px;
        }
      }

      .ctaLink {
        display: inline-block;
        margin: 0 auto 40px;

        h3 {
          display: inline;
          color: $color-dark-blue;
          text-decoration: underline;
        }
      }
    }
  }
}
