
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/carousel.scss';
  
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px 48px 20px;

  @include breakpoint(medium) {
    padding: 0 96px 64px 96px;
  }

  .content {
    display: flex;
    justify-content: center;
    width: 340px;
    height: 304px;
    border-radius: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.15);
    @include breakpoint(large) {
      width: 1008px;
      height: 210px;
    }

    .headline {
      max-width: 250px;
      margin-top: 30px;
      padding: 40px 30px;
      padding-top: 0;
      font-family: $font-axiforma;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.2;
      @include breakpoint(medium) {
        max-width: 450px;
        margin: 30px auto 0 0;
        font-size: 24px;
      }
      @include breakpoint(large) {
        align-self: center;
        margin-top: 0;
        padding: 45px 41px;
        font-size: 32px;
      }
    }
  }
}
