
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/carousel.scss';
  
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  padding: 48px 16px;
  background: $color-white;
  @include breakpoint(medium) {
    padding: 64px 32px;
  }
  @include breakpoint(large) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 64px;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  @include breakpoint(large) {
    max-width: 624px;
    max-height: 588px;
    padding: 0;
  }
}

.accordionWrapper {
  width: 100%;
  padding-top: 50px;
  @include breakpoint(medium) {
    max-width: 704px;
  }
  @include breakpoint(large) {
    max-width: 624px;
    padding-top: 0;
    padding-left: 60px;
  }

  .pca {
    border-radius: 8px;
    background: $color-white;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

    .pcaHeader {
      margin: 0;
      padding: 10px 24px;
      border-radius: 8px 8px 0 0;
      background: #154b8e;
      color: $color-white;
      font-family: $font-axiforma;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
    }

    .pcaSubContainer {
      padding: 24px;

      .pcaSubCopy {
        margin: 0;
        padding-bottom: 24px;
        color: #535353;
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
      }
    }

    .pcaCta {
      justify-content: flex-start;
      width: auto;
      height: 24px;
      padding: 4px;
      border-radius: 4px;
      background: transparent;
      color: #154b8e;
      font-family: $font-axiforma;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;

      .pcaCtaCopy {
        text-decoration: underline;
      }

      &:hover {
        border: none;
        color: #113c72;

        svg > path {
          fill: #113c72;
        }
      }

      &:focus {
        outline: 2px dashed #787878;
      }
    }
  }
}

.headline {
  margin-top: 0;
  margin-bottom: 16px;
  color: $color-primary-blue;
  font-family: $font-axiforma;
  font-size: 32px;
  font-weight: 700;
}

.subheading {
  margin-top: 0;
  padding-bottom: 45px;
  color: $color-matterhorn;
  @include breakpoint(medium) {
    max-width: 704px;
  }
}

.image {
  width: 100%;
  border-radius: 8px;
  @include breakpoint(medium) {
    max-width: 704px;
    max-height: 400px;
  }
  @include breakpoint(large) {
    max-width: 624px;
  }
}
