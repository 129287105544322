
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/carousel.scss';
  
.container {
  max-width: 82.5rem;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 16px;

  @include breakpoint(medium) {
    padding: 60px 30px;
  }

  @include breakpoint(large) {
    padding: 60px;
  }

  .headline {
    margin-top: 0;
    margin-bottom: 0;
    color: $color-primary-blue;
    font-family: $font-axiforma;
    font-size: 32px;
    font-weight: 700;
    text-align: center;

    @include breakpoint(medium) {
      margin-bottom: 20px;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      display: grid;
      grid-gap: 24px;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  svg {
    position: relative;
    top: 48px;
  }

  .content {
    margin-left: 60px;
  }

  .heading {
    margin: 0;
    color: $color-dark-gray;
    font-family: $font-axiforma;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .subheading {
    margin: 8px 0 0 0;
    color: $color-dark-gray;
    font-size: 14px;
  }

  .disclaimer {
    color: #53565a;
  }
}
